import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const OrderedListItem = ({ id, title, description, color = '#f28c37' }) => (
  <Box sx={{ margin: '0 0 1em 0.5em' }}>
    <Grid sx={{
      border: '1px solid #eaeaea',
      padding: '0.125em 0.5em 0.5em 0.5em',
      borderRadius: '4px'
    }} item container
      spacing={1}
      alignItems={'center'}
      flexWrap={'nowrap'}>
      <Grid item>
        <Avatar sx={{ width: 20, height: 20, backgroundColor: color, fontSize: '0.8rem', fontWeight: 700 }}>
          {id}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography>{title && <span style={{ fontWeight: 700 }}>{title}: </span>}{description}</Typography>
      </Grid>
    </Grid></Box>
);

export default OrderedListItem;