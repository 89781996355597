import {MDXProvider} from '@mdx-js/react'
import {serialize} from "./serialize";
import {MDXRemote} from "./mdx-remote";
import ThemeDefault from "./themes/default";
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemText,
  ThemeProvider
} from "@mui/material";
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GrainIcon from '@mui/icons-material/Grain';
import SupportIcon from '@mui/icons-material/Support';
import {Editor} from "@monaco-editor/react";
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import OrderedListItem from './components/OrderedListItem';
import Conversation from './components/Conversation';
import ConversationListItem from './components/ConversationListItem';
import EmailVariable from './components/EmailVariable';
import StepBody from './components/StepBody';
import Header2 from './components/Header2';
import Header1 from './components/Header1';
import EmailTemplate from './components/EmailTemplate';
import StepHeader from './components/StepHeader';
import ProgressTracker from './components/ProgressTracker';
import UnorderedListItem from './components/UnorderedListItem';
import Paragraph from './components/Paragraph';

function App() {
  const [mdx, setMdx] = React.useState(null);

  const handleChange = (value) =>{
    serialize(value).then(r => {
          setMdx(r)
        }
    )
  }

  const components = {
    h1: props => <h1   {...props} />,
    p:props => <p   {...props} />,
    EmailVariable: props => <EmailVariable {...props}/>,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    ExpandMoreIcon,
    OrderedListItem,
    Conversation,
    ConversationListItem:props => <ConversationListItem {...props} color="#743AE9"/>,
    UnorderedListItem,
    StepHeader,
    EmailIcon,
    MeetingRoomIcon,
    GrainIcon,
    SupportIcon,
    InfoIcon,
    ForumIcon,
    EmailTemplate: props => <EmailTemplate {...props}/>,
    Paragraph,
    Header1,
    Header2,
    ProgressTracker,
    StepBody: props => <StepBody {...props} height={'75vh'}/>
  }
  return (
      <ThemeProvider theme={ThemeDefault}>
        <CssBaseline/>
        <div className="App">
          <Grid container p={2} spacing={2} sx={{height:'90vh'}}>
            <Grid item xs={6}>
            <Editor defaultLanguage="mdx" height={'100%'} defaultValue="// some comment" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{width: '100%', padding: '1em', height: '100vh' -32, overflowY: 'auto',backgroundColor:'#fff'}}>
              <MDXProvider>
                {mdx && <MDXRemote {...mdx} components={components}/>}
              </MDXProvider>
              </Box>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
  );
}

export default App;
