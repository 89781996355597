import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';

const StepHeader = ({ icon, title, summary, color }) => {
  return <Grid item xs={12} container spacing={2} sx={{ paddingBottom: '1em !important' }}>
    <Grid item sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ color, ".MuiSvgIcon-root": { fontSize: '4rem' } }}>
        {icon}
      </Box>
    </Grid>
    <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography sx={{ fontWeight: '700' }}>{title}</Typography>
      <Typography color={'#9e9e9e'}>{summary}</Typography>
    </Grid>
    <Grid item xs={12} sx={{ paddingTop: '0.5em !important' }}>
      <Divider sx={{ borderBottom: '2px solid black;', width: '100%' }} />
    </Grid>
  </Grid>;
};


export default StepHeader;