import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const UnorderedListItem = ({ color = '#f28c37', title, description }) => (
  <Box sx={{ margin: '0 0 1em 0.5em' }}>
    <Grid sx={{
      border: '1px solid #eaeaea',
      padding: '0.125em 0.5em 0.5em 0.5em',
      borderRadius: '4px'
    }} item container
      spacing={1}
      alignItems={'center'}
      flexWrap={'nowrap'}>
      <Grid item>
        <Box sx={{ backgroundColor: color, width: '8px', height: '8px', borderRadius: '100%' }}></Box>
      </Grid>
      <Grid item>
        <Typography>{title && <span style={{ fontWeight: 700 }}>{title}: </span>}{description}</Typography>
      </Grid>
    </Grid>
  </Box>
);

export default UnorderedListItem;